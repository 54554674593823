.overview-image-container {
    width: 600px;
    float: right;
}

.overview-header {
    font-size: 34px;
    font-family: 'Rajdhani', sans-serif;    
}

.overview-text-1, .overview-text-2 {
    font-size: 20px;
}

.overview-text-2 {
    margin-top: 20px;
}

.headshot-container {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.headshot {
    width: 300px;
    height: 300px;
}

.overview-spacer {
    margin: 50px;
    border-top: 3px solid #212529;
}