.app {
    margin: 15px;
    max-width: 1600px;
}

.clearfix {
    clear: both;
}

.home-spacer {
    margin-top: 110px;
}