.hiscores {
    width: 285px;
    padding: 5px;
    float: right;
}

.hiscores-title {
    font-size: 20px;
    margin-bottom: 5px;
}

.hiscores-input {
    padding: 4px;
    width: 210px;
}

.hiscores-disclaimer {
    float: right;
    margin-top: 0.1em;
}

.hiscores-submit {    
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}