.navigation-bar {
    min-height: 80px;
}

.navigation-bar .navbar-brand {
    font-size: 30px;
}

.navigation-bar .nav-link {    
    margin-top: 7px;
    font-size: 26px;
    font-family: 'Rajdhani', sans-serif;
}

.navigation-bar .navbar-toggler {
    border: none;
    box-shadow: none;
}