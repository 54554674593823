.project {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
}

.project .card-title {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

.project .card-text {
    font-size: 14px;
}

.project .card-body {
    padding: 0;
}

.project-text-container {
    min-height: 80px;
    font-size: 14px;
}

.project-link-container {
    border-radius: 25px !important;
}